(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/percentage-reduce/assets/javascripts/random.js') >= 0) return;  svs.modules.push('/components/sport/percentage-reduce/assets/javascripts/random.js');
"use strict";


const randIntBetween = (min, max) => svsRandom.getRandomInt(min, max);
const randomFloatBetween = function (min, max) {
  let fixed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4;
  return parseFloat(svsRandom.getRandomFloat(min, max, fixed));
};
setGlobal('svs.components.sport.percentageReduce.random', {
  randomFloatBetween,
  randIntBetween
});

 })(window);